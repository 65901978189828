<template>
  <div class="content-wrapper">
    <bo-page-header></bo-page-header>
    <div class="content pt-0">
      <b-card body-class="d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
        <div class="d-flex align-items-center mb-3 mb-md-0">
          <h5 class="font-weight-semibold mb-0">Sosial Media Overview</h5>
        </div>
        <div class="d-flex align-items-center mb-3 mb-md-0">
          <ul class="nav nav-pills justify-content-end mb-0">
            <li class="nav-item"><a href="#right-pill1" class="nav-link bg-indigo-400 active"
                data-toggle="tab">Days</a></li>
            <li class="nav-item"><a href="#right-pill2" class="nav-link" data-toggle="tab">Week</a></li>
            <li class="nav-item"><a href="#right-pill2" class="nav-link" data-toggle="tab">Month</a></li>
          </ul>
          <div class="ml-3">
            <div class="input-group">
              <span class="input-group-prepend">
                <span class="input-group-text"><i class="icon-calendar22"></i></span>
              </span>
              <date-range-picker
                class="form-control"
                control-container-class=""
                opens="left"
                append-to-body
                :date-range="dateRange"
                style="min-width: 180px"
              />
            </div>
          </div>
        </div>
      </b-card>
      <b-card>
        <template #header>
          <div class="row">
            <div class="col-md-10">
              <h6 class="card-title font-weight-bold">User Overview</h6>
            </div>
            <div class="col-md-2">
              <b-form-select
                v-model="selFollow"
                :options="followOptions"
                size="sm"
              />
            </div>
          </div>
        </template>
        
        <!-- <a href="" class="btn_add"><i class="icon-plus2 mr-0"></i></a> -->
        <div class="chart-container">
          <v-chart :option="mentionSummaryOptions" class="chart has-fixed-height" />
        </div>
      </b-card>
      <b-card no-body>
        <div class="sosmed-view-wrapper">
          <div class="wrap_sosmed_view">
            <div class="ic_sosmed bg_ig "><i class="icon-instagram"></i></div>
            <div class="wrap_text_prev">
              <div class="row">
                <div class="col-md-12">
                  <h2>Instagram Follower</h2>
                  <p>100</p>
                </div>

              </div>
            </div>
          </div>
          <div class="wrap_sosmed_view">
            <div class="ic_sosmed bg_tw "><i class="icon-twitter"></i></div>
            <div class="wrap_text_prev">
              <div class="row">
                <div class="col-md-12">
                  <h2>Twitter Follower</h2>
                  <p>100</p>
                </div>
              </div>
            </div>
          </div>
          <div class="wrap_sosmed_view noborder_r">
            <div class="ic_sosmed bg_fb"><i class="icon-facebook"></i></div>
            <div class="wrap_text_prev">
              <div class="row">
                <div class="col-md-12">
                  <h2>Facebook FanPage</h2>
                  <p>100</p>
                </div>

              </div>
            </div>
          </div>
          <div class="wrap_sosmed_view border-top">
            <div class="ic_sosmed bg_ig "><i class="icon-instagram"></i></div>
            <div class="wrap_text_prev">
              <div class="row">
                <div class="col-md-12">
                  <h2>Instagram Post Reach</h2>
                  <p>100</p>
                </div>

              </div>
            </div>
          </div>
          <div class="wrap_sosmed_view border-top">
            <div class="ic_sosmed bg_tw "><i class="icon-twitter"></i></div>
            <div class="wrap_text_prev">
              <div class="row">
                <div class="col-md-12">
                  <h2>Twitter Post Reach</h2>
                  <p>100</p>
                </div>

              </div>
            </div>
          </div>
          <div class="wrap_sosmed_view border-top noborder_r">
            <div class="ic_sosmed bg_fb"><i class="icon-facebook"></i></div>
            <div class="wrap_text_prev">
              <div class="row">
                <div class="col-md-12">
                  <h2>Facebook Fanpage Reach</h2>
                  <p>100</p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <bo-footer></bo-footer>
  </div>
</template>

<script>
import chartDatas from '@/dummies/chartDatas.js'
  import {
    use
  } from "echarts/core";
  import {
    CanvasRenderer
  } from "echarts/renderers";
  import {
    LineChart,
  } from "echarts/charts";
  import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DataZoomComponent,
  } from "echarts/components";
  import VChart from 'vue-echarts'
import GlobalVue from '../../libs/Global.vue';

  use([
    CanvasRenderer,
    LineChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DataZoomComponent,
  ])
export default {
  extends: GlobalVue,
  components: {
    VChart,
  },
  data() {
    return {
      dateRange: {},
      mentionSummaryOptions: chartDatas.mentionSummaryOptions,
      selFolllow: 'Follower',
      followOptions: [ 'Follower', 'Following' ]
    }
  }
}
</script>